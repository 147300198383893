/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogInboundCallStatusMapper,
  CommunicationLogOutboundCallStatusMapper,
} from "@/components/client/communication-log/enumerations";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { DateUtility } from "@/components/client/date";
import {
  Call,
  CallDirection,
  CallRouting,
  UserProfile,
} from "@/components/client/graphql";

type InternalCall = Pick<
  Call,
  | "callSid"
  | "date"
  | "dateEnded"
  | "dateStarted"
  | "direction"
  | "duration"
  | "from"
  | "id"
  | "status"
  | "summary"
  | "to"
> & {
  routings?: {
    id: string;
    date: string;
    status: CallRouting["status"];
    user?: {
      profile: {
        fullName: string;
      };
    };
  }[];
  user?: {
    profile?: {
      fullName?: UserProfile["fullName"];
    };
  } | null;
};

type MapCallArgs = {
  call?: InternalCall | null;
};

/**
 * Maps a "Call" record to a "CommunicationLog" record.
 */
export function mapCall({ call }: MapCallArgs): CommunicationLog | null {
  if (!call) {
    return null;
  }

  /**
   * Direction of the call.
   */
  const direction =
    call.direction === CallDirection.Inbound
      ? CommunicationDirection.INBOUND
      : CommunicationDirection.OUTBOUND;

  /**
   * Phone number of the customer.
   */
  const clientPhoneNumber =
    call.direction === CallDirection.Inbound ? call.from : call.to;

  return {
    agentName: call.user?.profile?.fullName ?? "",
    callSid: call.callSid!,
    clientPhoneNumber,
    date: new Date(call.date),
    dateEnded: call.dateEnded ? new Date(call.dateEnded) : undefined,
    dateStarted: call.dateStarted ? new Date(call.dateStarted) : undefined,
    direction,
    duration: call.duration ?? 0,
    from: call.from!,
    id: call.id!,
    routings:
      call.routings?.map((routing) => ({
        id: routing.id,
        agentName: routing.user?.profile.fullName!,
        date: new Date(routing.date),
        status: routing.status,
      })) ?? [],
    status:
      call.direction === CallDirection.Inbound
        ? CommunicationLogInboundCallStatusMapper[
            call.status as keyof typeof CommunicationLogInboundCallStatusMapper
          ]
        : CommunicationLogOutboundCallStatusMapper[
            call.status as keyof typeof CommunicationLogOutboundCallStatusMapper
          ],
    summary: call.summary ?? undefined,
    time: DateUtility.getTime({ date: new Date(call.date) }),
    to: call.to!,
  } satisfies CommunicationLog;
}
