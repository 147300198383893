import { CallStatus } from "@/components/client/graphql";

export enum CommunicationLogStatus {
  /**
   * The status for inbound calls that are canceled before being queued or outbound
   * calls that are cancelled before being answered by the client.
   */
  CANCELED = "Canceled",
  /**
   * The status for calls that are concluded and has wrapped up.
   */
  COMPLETED = "Completed",
  /**
   * The status for inbound calls missed by the agents.
   */
  MISSED = "Missed",
  /**
   * The status for outbound calls miseed by the client.
   */
  NO_RESPONSE = "No Response",
  /**
   * The status for inbound/outbound calls that are answered and still ongoing.
   */
  ONGOING = "Ongoing",
  /**
   * The status for inbound calls that are received but yet to be queued.
   */
  PENDING = "Pending",
  /**
   * The status for outbound calls that are rejected by the agents/client
   */
  DECLINED = "Declined",
  /**
   * The status for inbound/outbound calls that are ringing.
   */
  RINGING = "Ringing",
  /**
   * The status for inbound/outbound calls that have concluded and are wrapping up.
   */
  WRAPPING_UP = "Wrap Up",
}

/**
 * GraphQL Call Status to Component Communication Status Mapper for inbound communications.
 */
export const CommunicationLogInboundCallStatusMapper = {
  [CallStatus.Canceled]: CommunicationLogStatus.CANCELED,
  [CallStatus.Completed]: CommunicationLogStatus.COMPLETED,
  [CallStatus.InProgress]: CommunicationLogStatus.ONGOING,
  [CallStatus.Missed]: CommunicationLogStatus.MISSED,
  /**
   * This status will not show up in the communication log because it is not
   * assigned to any agents yet and is still in the process of being queued.
   */
  [CallStatus.Pending]: CommunicationLogStatus.PENDING,
  [CallStatus.Queued]: CommunicationLogStatus.RINGING,
  [CallStatus.Rejected]: CommunicationLogStatus.DECLINED,
  [CallStatus.WrappingUp]: CommunicationLogStatus.WRAPPING_UP,
};

/**
 * GraphQL Call Status to Component Communication Status Mapper for outbound communications.
 */
export const CommunicationLogOutboundCallStatusMapper = {
  [CallStatus.Canceled]: CommunicationLogStatus.CANCELED,
  [CallStatus.Completed]: CommunicationLogStatus.COMPLETED,
  [CallStatus.InProgress]: CommunicationLogStatus.ONGOING,
  [CallStatus.Missed]: CommunicationLogStatus.NO_RESPONSE,
  /**
   * This status will not show up in the communication log because it is not
   * assigned to any agents yet and is still in the process of being queued.
   */
  [CallStatus.Pending]: CommunicationLogStatus.PENDING,
  [CallStatus.Queued]: CommunicationLogStatus.RINGING,
  [CallStatus.Rejected]: CommunicationLogStatus.DECLINED,
  [CallStatus.WrappingUp]: CommunicationLogStatus.WRAPPING_UP,
};
