"use client";

/**
 * Third-party libraries.
 */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogStatus,
} from "@/components/client/communication-log";
import {
  CommunicationLog,
  CommunicationLogs,
} from "@/components/client/communication-log/types";
import { useApplicationContext } from "@/components/client/context";
import { DateUtility } from "@/components/client/date";
import {
  CallsAgentQuery,
  useCallEventSubscription,
  useCallLazyQuery,
  useCallsAgentQuery,
} from "@/components/client/graphql";
import { CommunicationLogUtility } from "./utilities";

/**
 * Communication Log context.
 */
type CommunicationLogContext = {
  /**
   * Communication logs.
   */
  communicationLogs: CommunicationLogs;
  /**
   * Indicates that the communication logs are loading.
   */
  communicationLogsLoading: boolean;
  /**
   * Selected communication log.
   * This is the currently active communication log from the communication log list.
   */
  selectedCommunicationLog: CommunicationLog | null;
  /**
   * Indicates that the selected communication log is loading.
   */
  selectedCommunicationLogLoading: boolean;
  /**
   * Sets the selected communication log.
   * This causes the other panels to display corresponding information in respect
   * of the active communication log record.
   */
  setSelectedCommunicationLog: (
    communicationLog: CommunicationLog | null
  ) => void;
};

/**
 * Communication Log related context.
 */
const CommunicationLogContext = React.createContext<CommunicationLogContext>({
  communicationLogs: [],
  communicationLogsLoading: true,
  selectedCommunicationLog: null,
  selectedCommunicationLogLoading: true,
  setSelectedCommunicationLog: () => {},
});

/**
 * Use Communication Log context.
 */
export const useCommunicationLogContext = () => {
  return React.useContext(CommunicationLogContext);
};

/**
 * Communication log context provider.
 */
export const CommunicationLogContextProvider = ({
  children,
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Application context.
   */
  const { mockData } = useApplicationContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  /**
   * Calls agent query.
   */
  const {
    data: callsAgentData,
    loading: callsAgentLoading,
    error: callsAgentError,
    refetch: refetchCallsAgent,
  } = useCallsAgentQuery();

  const [getCall] = useCallLazyQuery();

  /**
   * Call event subscription.
   */
  useCallEventSubscription({
    onData: (data) => {
      // Refetch calls whenever there is a call event.
      refetchCallsAgent();
    },
  });

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The communication log records in the system.
   */
  const communicationLogs = useMemo<CommunicationLogs>(() => {
    if (mockData) {
      return [
        {
          label: "Today",
          logs: [
            {
              id: "1",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.RINGING,
              time: "11:54 AM",
              to: "Jane Doe",
            },
            {
              id: "2",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.RINGING,
              time: "11:50 AM",
              to: "Josh Doe",
            },
            {
              id: "3",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.ONGOING,
              time: "3:30 PM",
              to: "Jane Smith",
            },
            {
              id: "4",
              agentName: "Alice Johnson",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Bob Johnson",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Alice Johnson",
              status: CommunicationLogStatus.ONGOING,
              time: "9:45 AM",
              to: "Bob Johnson",
            },
            {
              id: "5",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.MISSED,
              time: "10:30 AM",
              to: "Jane Doe",
            },
            {
              id: "6",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Josh Doe",
            },
            {
              id: "7",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.MISSED,
              time: "3:30 PM",
              to: "Jane Smith",
            },
            {
              id: "8",
              agentName: "Alice Johnson",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Bob Johnson",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Alice Johnson",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Bob Johnson",
            },
            {
              id: "9",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.MISSED,
              time: "10:30 AM",
              to: "Jane Doe",
            },
            {
              id: "10",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Josh Doe",
            },
            {
              id: "11",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.MISSED,
              time: "3:30 PM",
              to: "Jane Smith",
            },
          ],
        },
        {
          label: "Yesterday",
          logs: [
            {
              id: "12",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.MISSED,
              time: "3:30 PM",
              to: "Jane Smith",
            },
            {
              id: "13",
              agentName: "Alice Johnson",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Bob Johnson",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Alice Johnson",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Bob Johnson",
            },
            {
              id: "14",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.MISSED,
              time: "10:30 AM",
              to: "Jane Doe",
            },
            {
              id: "15",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Josh Doe",
            },
            {
              id: "16",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.MISSED,
              time: "3:30 PM",
              to: "Jane Smith",
            },
            {
              id: "17",
              agentName: "Alice Johnson",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Bob Johnson",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Alice Johnson",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Bob Johnson",
            },
          ],
        },
        {
          label: "Two Days Ago",
          logs: [
            {
              id: "18",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.MISSED,
              time: "10:30 AM",
              to: "Jane Doe",
            },
            {
              id: "19",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Josh Doe",
            },
            {
              id: "20",
              agentName: "John Smith",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Smith",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "John Smith",
              status: CommunicationLogStatus.MISSED,
              time: "3:30 PM",
              to: "Jane Smith",
            },
            {
              id: "21",
              agentName: "Alice Johnson",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Bob Johnson",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "Alice Johnson",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Bob Johnson",
            },
            {
              id: "22",
              agentName: "John Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Jane Doe",
              date: new Date(),
              direction: CommunicationDirection.INBOUND,
              from: "John Doe",
              status: CommunicationLogStatus.MISSED,
              time: "10:30 AM",
              to: "Jane Doe",
            },
            {
              id: "23",
              agentName: "Jake Doe",
              callSid: "CA12139sadf123947123423497234",
              clientPhoneNumber: "Josh Doe",
              date: new Date(),
              direction: CommunicationDirection.OUTBOUND,
              from: "Jake Doe",
              status: CommunicationLogStatus.COMPLETED,
              time: "9:45 AM",
              to: "Josh Doe",
            },
          ],
        },
      ];
    }

    // There are no calls.
    if (!callsAgentData?.callsAgent.length) {
      return [
        {
          label: "Today",
          logs: [],
        },
      ];
    }

    /**
     * Comminication logs grouped by date.
     * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
     */
    const _communicationLog: CommunicationLogs = [
      {
        label: "Today",
        logs: [],
      },
    ];

    /**
     * Adds the communication log to a communication log group.
     * If the group does not exist, it will be created.
     */
    function addCommunicationLogToGroup({
      call,
      groupLabel,
    }: {
      /**
       * The call to add to the group.
       */
      call: CallsAgentQuery["callsAgent"][0];
      /**
       * Label of the group to add the communication log to.
       * If this group does not exist, it will be created.
       */
      groupLabel: string;
    }) {
      /**
       * Index of the communication log group for matching the given group label.
       */
      let communicationLogGroupIndex = _communicationLog.findIndex(
        (log) => log.label === groupLabel
      );

      // There is no existing communication log group for the given group label.
      if (communicationLogGroupIndex === -1) {
        // Create a new communication log group.
        _communicationLog.push({
          label: groupLabel,
          logs: [],
        });

        // Push the communication log to the newly created group.
        communicationLogGroupIndex = _communicationLog.findIndex(
          (log) => log.label === groupLabel
        );
      }

      /**
       * Communication log created from the call record.
       */
      const communicationLog = CommunicationLogUtility.mapCall({ call });

      // Add the communication log to the group.
      if (communicationLog) {
        _communicationLog[communicationLogGroupIndex].logs.push(
          communicationLog
        );
      }
    }

    callsAgentData.callsAgent.forEach((call) => {
      const callDate = new Date(call.date);

      // Get today's communication logs.
      if (DateUtility.isToday({ date: callDate })) {
        addCommunicationLogToGroup({ call, groupLabel: "Today" });
      }
      // Get yesterday's comminication logs.
      else if (DateUtility.isYesterday({ date: callDate })) {
        addCommunicationLogToGroup({ call, groupLabel: "Yesterday" });
      }
      // Get communication logs older than yesterday.
      else {
        addCommunicationLogToGroup({
          call,
          // Use the date as the group label.
          groupLabel: DateUtility.getDate({ date: callDate }),
        });
      }
    });

    return _communicationLog;
  }, [callsAgentData?.callsAgent, mockData]);

  /**
   * Indicates which communication log is currently active on the screen.
   * This could control which subpanels are shown.
   */
  const [selectedCommunicationLog, _setSelectedCommunicationLog] =
    useState<CommunicationLog | null>(null);

  const [selectedCommunicationLogLoading, setSelectedCommunicationLogLoading] =
    useState<boolean>(true);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Sets the selected communication log.
   */
  const setSelectedCommunicationLog = useCallback(
    (communicationLog: CommunicationLog | null) => {
      setSelectedCommunicationLogLoading(true);

      if (!communicationLog) {
        _setSelectedCommunicationLog(communicationLog);
        return;
      }

      getCall({
        fetchPolicy: "network-only",
        variables: {
          filter: {
            id: communicationLog.id,
          },
        },
        onCompleted: (data) => {
          if (!data.call) {
            return;
          }

          _setSelectedCommunicationLog(
            CommunicationLogUtility.mapCall({
              call: data.call,
            })
          );

          setSelectedCommunicationLogLoading(false);
        },
        onError: (error) => {
          setSelectedCommunicationLogLoading(false);
        },
      });
    },
    [getCall]
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the selected communication log when the call list changes.
   * - Removes it if it is not included anymore on the updated list. This might
   * happen when the call is reassigned to a different agent.
   * - Update the status if the call is still in the list.
   */
  useEffect(() => {
    /**
     * Indicates whether the selected communication log exists in the communication logs.
     *
     * This is used to determine whether the selected communication log should be reset.
     *
     * A selected communication log might not be valid in the communication logs with status
     * queued because it might have been reassigned to a different agent.
     */
    let selectedCommunicationLogExists = false;

    /**
     * Check if the existing selected communication log is still in the list after
     * a refetch.
     *
     * Reset the selected communication log so we can make sure the status
     * is up to date.
     */
    for (const call of callsAgentData?.callsAgent ?? []) {
      if (selectedCommunicationLog?.id !== call.id) {
        continue;
      }

      selectedCommunicationLogExists = true;
      setSelectedCommunicationLog(
        CommunicationLogUtility.mapCall({
          call,
        })
      );
    }

    /**
     * Clear the selected communication log so we can remove any panels
     * that are associated with the communication log if:
     * - There are no communication logs.
     * - The selected communication log doesn't exist anymore in the list. This
     * might happen in the case of a queued call that was reassigned to a different agent.
     */
    if (!selectedCommunicationLogExists) {
      setSelectedCommunicationLog(null);
    }
  }, [
    callsAgentData?.callsAgent,
    selectedCommunicationLog?.id,
    setSelectedCommunicationLog,
  ]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <CommunicationLogContext.Provider
      value={{
        communicationLogs,
        communicationLogsLoading: callsAgentLoading,
        selectedCommunicationLog,
        selectedCommunicationLogLoading,
        setSelectedCommunicationLog: _setSelectedCommunicationLog,
      }}
    >
      {children}
    </CommunicationLogContext.Provider>
  );
};
