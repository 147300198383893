/**
 * Gets the date from the date object.
 */
export function getDate({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  let day: string | number = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  /**
   * Zero (0) padded day.
   */
  day = day < 10 ? "0" + day : day;

  return day + " " + monthNames[month] + " " + year;
}

/**
 * Gets the time from a date object.
 * Returns hh:mm AM/PM format.
 */
export function getTime({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  let seconds: number | string = date.getSeconds();
  let milliseconds: number | string = date.getMilliseconds();

  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  // 0 Hour should be 12.
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  milliseconds =
    milliseconds < 10
      ? "00" + milliseconds
      : milliseconds < 100
      ? "0" + milliseconds
      : milliseconds;

  return (
    // hours + ":" + minutes + " " + amPm
    hours + ":" + minutes + ":" + seconds + " " + amPm
    // hours + ":" + minutes + ":" + seconds + "." + milliseconds + " " + amPm
  );
}

/**
 * Get date and time from a date object.
 */
export function getDateTime({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  return (
    getDate({
      date,
    }) +
    " " +
    getTime({
      date,
    })
  );
}

/**
 * Check if date and time is today.
 */
export function isToday({
  date,
}: {
  /**
   * Date to check.
   */
  date: Date;
}) {
  /**
   * Date today.
   */
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

/**
 * Check if date is yesterday.
 */
export function isYesterday({
  date,
}: {
  /**
   * Date to check.
   */
  date: Date;
}) {
  const today = new Date();
  const yesterday = new Date(today);

  // Subtract one day from today
  yesterday.setDate(today.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}
