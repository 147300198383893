"use client";

/**
 * Third-party libraries.
 */
import { Button, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import {
  useCallAcceptMutation,
  useCallHangUpMutation,
  useCallRejectMutation,
} from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { useTwilioContext } from "@/components/client/twilio";
import { ASSET_ROUTE } from "@/components/common/route";
import { StringUtility } from "@/components/common/string";
import { Call } from "@twilio/voice-sdk";

/**
 * Communication log card properties.
 */
export type CommunicationLogCardProps = {
  /**
   * Data to display on the card.
   */
  data: CommunicationLog;
  /**
   * Callback when the card is clicked.
   */
  onClick?: (args: { communicationLog: CommunicationLog }) => void;
};

/**
 * A single entry in the communication log list.
 */
export const CommunicationLogCard = ({
  data,
  onClick,
}: CommunicationLogCardProps) => {
  const {
    agentName,
    callSid,
    clientPhoneNumber,
    direction,
    from,
    id,
    status,
    time,
    to,
  } = data;

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { getCall } = useTwilioContext();

  const { communicationLogsLoading, selectedCommunicationLog } =
    useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    switch (status) {
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.COMPLETED:
      case CommunicationLogStatus.WRAPPING_UP:
        return "phone-down";
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
      case CommunicationLogStatus.ONGOING:
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
        return "phone";
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    /**
     * Indicates that this communication log is actively selected.
     */
    const isSelected = selectedCommunicationLog?.id === id;

    switch (status) {
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
      case CommunicationLogStatus.WRAPPING_UP:
        return {
          background: isSelected ? "bg-neutral-gray" : "bg-sky-100",
          icon: "!text-primary-navy-main",
          text: "",
        };
      case CommunicationLogStatus.ONGOING:
        return {
          background: "bg-semantic-green",
          icon: "!text-white",
          text: "text-white",
        };
      case CommunicationLogStatus.COMPLETED:
        return {
          background: isSelected ? "bg-neutral-gray" : "bg-white",
          icon: "!text-semantic-green",
          text: "",
        };
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
        return {
          background: isSelected ? "bg-neutral-gray" : "bg-white",
          icon: "!text-semantic-red",
          text: "",
        };
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [id, selectedCommunicationLog?.id, status]);

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const [call, setCall] = useState<Call | null>(null);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [acceptCall, { loading: acceptingCall }] = useCallAcceptMutation();
  const [rejectCall, { loading: rejectingCall }] = useCallRejectMutation();
  const [hangupCall, { loading: hangingUpCall }] = useCallHangUpMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const handleOnClick = useCallback(async () => {
    onClick?.({ communicationLog: data });
  }, [data, onClick]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Links the Twilio call to this card if applicable.
   * This enables the hangup and mute buttons to work.
   */
  useEffect(() => {
    if (!callSid) {
      setCall(null);
      return;
    }

    setCall(getCall({ callSid }));
  }, [callSid, getCall]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <div
      className={`self-stretch justify-start items-center gap-2 flex p-4 ${color.background} animate-slide-left ${color.text} cursor-pointer`}
      onClick={handleOnClick}
    >
      <Icon src={icon} className={`!text-2xl ${color.icon}`} />
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
        <div className="flex flex-col self-stretch">
          <div className="self-stretch justify-between items-center flex">
            <div
              className={`communication-log-card-status text-slate-600 ${color.text} text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]`}
            >
              {status} ({direction})
            </div>
            <div
              className={`communication-log-card-time  text-gray-400  text-xs font-normal leading-none 
                ${
                  status === CommunicationLogStatus.RINGING ||
                  status === CommunicationLogStatus.ONGOING
                    ? "hidden"
                    : ""
                } ${color.text} `}
            >
              {time}
            </div>
          </div>
          <div
            className={`communication-log-card-contact-name grow shrink basis-0 text-gray-400 ${color.text} text-sm font-normal font-['Mont-Regular'] leading-[17.50px]`}
          >
            {clientPhoneNumber}
          </div>
        </div>
        <div className="w-full justify-start items-center gap-1 flex">
          <div
            className={
              "w-4 h-4 bg-slate-100 rounded-[100px] justify-center items-center flex"
            }
          >
            <div className="w-4 h-4 relative">
              <div
                className={`communication-log-card-agent-initials left-[3px] top-[3px] absolute text-center text-slate-600 text-[8px] font-normal font-['Mont-SemiBold'] leading-[10.40px]`}
              >
                {StringUtility.getInitials({
                  input: agentName,
                  maxLength: 2,
                })}
              </div>
            </div>
          </div>
          <div
            className={`communication-log-card-agent-name text-slate-600 ${
              color.text
            } ${
              agentName?.length ? "" : "!text-semantic-red"
            } text-xs font-normal font-['Mont-SemiBold'] leading-none text-nowrap text-ellipsis overflow-hidden max-w-44`}
          >
            {agentName?.length ? agentName : "Unassigned"}
          </div>
        </div>
        {status === CommunicationLogStatus.RINGING && (
          <audio src={ASSET_ROUTE.AUDIO.CALL_RINGTONE} autoPlay loop />
        )}
      </div>
      <div className="justify-start items-start gap-2 flex">
        {/* <Button
          icon={
            <Icon src={muted ? "microphone-mute" : "microphone"} />
          }
          onClick={async () => {
            // TODO Insert toggle mute logic.
            call?.mute(!muted);
            setMuted(!muted);
            // onToggleMute({ log: data });
          }}
          shape="circle"
          style={{
            backgroundColor: "var(--neutral",
            display: status === CommunicationLogStatus.ONGOING ? "" : "none",
            height: 40,
            width: 40,
          }}
          type="primary"
        /> */}
        <Tooltip title="Accept">
          <Button
            disabled={
              acceptingCall || rejectingCall || communicationLogsLoading
            }
            icon={<Icon src="phone" />}
            loading={acceptingCall}
            onClick={async () => {
              await acceptCall({
                variables: {
                  input: {
                    callSid,
                  },
                },
              });
            }}
            shape="circle"
            style={{
              backgroundColor: "var(--semantic-green)",
              display:
                direction === CommunicationDirection.INBOUND &&
                status === CommunicationLogStatus.RINGING
                  ? ""
                  : "none",
              height: 40,
              width: 40,
            }}
            type="primary"
          />
        </Tooltip>
        <Tooltip
          title={
            status === CommunicationLogStatus.RINGING
              ? direction === CommunicationDirection.INBOUND
                ? "Reject"
                : "Hangup"
              : "End"
          }
        >
          <Button
            disabled={
              acceptingCall ||
              hangingUpCall ||
              rejectingCall ||
              communicationLogsLoading
            }
            icon={<Icon src="phone-down" />}
            loading={rejectingCall || hangingUpCall}
            onClick={async () => {
              //Call is ringing.
              if (status === CommunicationLogStatus.RINGING) {
                // Inbound call.
                if (direction === CommunicationDirection.INBOUND) {
                  await rejectCall({
                    variables: {
                      input: {
                        callSid,
                      },
                    },
                  });
                }
                // Outbound call.
                else if (direction === CommunicationDirection.OUTBOUND) {
                  await hangupCall({
                    variables: {
                      input: {
                        callSid,
                      },
                    },
                  });
                }
              }
              // Call is already ongoing.
              else if (status === CommunicationLogStatus.ONGOING) {
                call?.disconnect();
              }
            }}
            shape="circle"
            style={{
              backgroundColor: "var(--semantic-red)",
              display:
                status === CommunicationLogStatus.RINGING ||
                status === CommunicationLogStatus.ONGOING
                  ? ""
                  : "none",
              height: 40,
              width: 40,
            }}
            type="primary"
          />
        </Tooltip>
      </div>
    </div>
  );
};
